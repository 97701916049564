import { render, staticRenderFns } from "./indexDisciplina.vue?vue&type=template&id=381c476e&scoped=true&"
import script from "./indexDisciplina.vue?vue&type=script&lang=js&"
export * from "./indexDisciplina.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "381c476e",
  null
  
)

export default component.exports
<template>
  <b-row>
    <b-col>
      <b-card>
        <div style="display: flex; justify-content: space-between;">
          <div style="display: flex">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="35"
                height="35"
                viewBox="0 0 172 172"
                style=" fill:#000000; cursor: pointer"
                @click="$router.go(-1)"
            ><g
                fill="none"
                fill-rule="nonzero"
                stroke="none"
                stroke-width="1"
                stroke-linecap="butt"
                stroke-linejoin="miter"
                stroke-miterlimit="10"
                stroke-dasharray=""
                stroke-dashoffset="0"
                font-family="none"
                font-weight="none"
                font-size="none"
                text-anchor="none"
                style="mix-blend-mode: normal"
            ><path
                d="M0,172v-172h172v172z"
                fill="none"
            /><g fill="#08071a"><path d="M86,154.8c37.9948,0 68.8,-30.8052 68.8,-68.8c0,-37.9948 -30.8052,-68.8 -68.8,-68.8c-37.9948,0 -68.8,30.8052 -68.8,68.8c0,37.9948 30.8052,68.8 68.8,68.8zM53.27987,81.94653l22.93333,-22.93333c1.118,-1.118 2.58573,-1.67987 4.05347,-1.67987c1.46773,0 2.93547,0.56187 4.05347,1.67987c2.24173,2.24173 2.24173,5.8652 0,8.10693l-13.14653,13.14653h43.49307c3.1648,0 5.73333,2.5628 5.73333,5.73333c0,3.17053 -2.56853,5.73333 -5.73333,5.73333h-43.49307l13.14653,13.14653c2.24173,2.24173 2.24173,5.8652 0,8.10693c-2.24173,2.24173 -5.8652,2.24173 -8.10693,0l-22.93333,-22.93333c-2.24173,-2.24173 -2.24173,-5.8652 0,-8.10693z" /></g></g></svg>

            <div class="titulo-pagina ml-1">
              <div style="align-self: center">
                Cadastrar Disciplina
              </div>
            </div>
          </div>
        </div>
        <b-row class="mt-2">
          <b-col>
            <b-form-group

              label="Nome da Disciplina:"
              label-for="nome"
            >
              <b-form-input
                  style="width: 500px"
                id="nome"
                v-model="nome"
                placeholder="Nome"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <div style="display: flex">
              <b-button
                  class="px-3"
                  variant="primary"
                  @click="SalvarDisciplina()"
              >
                <feather-icon
                    icon="CheckIcon"
                    class="mr-50"
                />
                Salvar Disciplina
              </b-button>
              <div style="align-self: center; cursor: pointer;"
                   class="ml-1"
                   variant="primary"
                   block
                   @click="LimparCampos()"
              >
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                />
                Limpar Campos
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BFormGroup, BRow } from 'bootstrap-vue'
// import vSelect from 'vue-select'

export default {
  name: 'IndexContrato',
  components: {
    BRow,
    BCol,
    BFormGroup,
    // vSelect,
  },
  data() {
    return {
      url: 'admin/disciplina_categoria',
      data: {},
      // ---------- Dados V-Model ----------- \\
      id: null,
      nome: null,
      descricao: null,
    }
  },

  async created() {
    this.BuscarDados(this.url)
    await this.$http.get('admin/cliente').then(cliente => {
      const clientesBanco = cliente.data.data
      const clientes = []
      clientesBanco.forEach(c => {
        clientes.push({
          value: c.id,
          name: c.nome,
        })
      })
      this.opcoescliente = clientes
    })
    await this.$http.get('admin/contrato').then(contrato => {
      const contratosBanco = contrato.data.data
      const contratos = []
      contratosBanco.forEach(cont => {
        contratos.push({
          value: cont.id,
          name: cont.numero_contrato,
        })
      })
      this.opcoescliente = contratos
    })
  },
  methods: {
    BuscarDados(url = this.url) {
      this.$http.get(url)
        .then(resp => {
          this.data = resp.data
        })
    },
    Notificacao(cor, msg) {
      this.$bvToast.toast(msg, {
        title: 'NOTIFICAÇÃO',
        variant: cor,
        solid: true,
      })
    },
    reloadTable(tableProps) {
      this.mostraPopUp = false
      this.BuscarDados(this.url, tableProps)
    },
    LimparCampos() {
      this.id = null
      this.nome = null
      this.descricao = null
    },
    SalvarDisciplina() {
      const obj = {
        nome: this.nome,
        descricao: this.descricao,
      }
      this.$loading(true)
      if (this.id === null) {
        this.$http.post('admin/disciplina_categoria', obj).then(resp => {
          if (resp.data.success) {
            this.Notificacao('success', resp.data.success)
            this.$loading(false)
            this.$router.push({
              name: 'adm-disciplinas',
            })
          }
          if (resp.data.error) {
            this.Notificacao('danger', resp.data.error)
            this.$loading(false)
          }
          this.reloadTable()
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
